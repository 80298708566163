import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AperturaCaja } from '../interfaces/aperturaCaja.interface';
import { Gasto } from '../interfaces/gasto.interface';
import { Monedero } from '../interfaces/monedero.interface';

@Injectable({
  providedIn: 'root'
})
export class CajasService {
  token:string = localStorage.getItem('tp-Token') || '';
  headers = new HttpHeaders({
    'Content-Type':'application/x-www-form-urlencoded',
    'Tp-Token': localStorage.getItem('tp-Token') || ''
  });

  constructor(private http: HttpClient) { }

  aperturaCaja(data:AperturaCaja){
    const base_url = `${ environment.base_url }/AperturasCaja`;
    const url = `${ base_url }/postApertura`;
    // console.log(url);
    
    return this.http.post(url, data, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getCajaAbierta(idSerieFactura:number){
    const base_url = `${ environment.base_url }/AperturasCaja`;
    const url = `${ base_url }/getCajaAbierta/${idSerieFactura}`;
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  actualizarApertura(data:AperturaCaja, id:number){
    const base_url = `${ environment.base_url }/AperturasCaja`;
    const url = `${ base_url }/putApertura/${id}`;
    
    return this.http.put(url, data, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  cobroTicket(id:number, idSerieFactura:number){
    const base_url = `${ environment.base_url }/MovimientosCaja`;
    const url = `${ base_url }/cobroTicket/${id}/${idSerieFactura}`;
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getApertura(idCaja:number){
    const base_url = `${ environment.base_url }/AperturasCaja`;
    const url = `${ base_url }/getAperturaCaja/${idCaja}`;
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getArqueo(idCaja:number){
    const base_url = `${ environment.base_url }/MovimientosCaja`;
    const url = `${ base_url }/getArqueoCaja/${idCaja}`;
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getAllArqueos(pagina:number =1, rows:number = 15, desdeFecha: Date, hastaFecha: Date, orden:string = 'fechaCaja', desc:number = 0){
    const base_url = `${ environment.base_url }/AperturasCaja`;
    const url = `${ base_url }/getAll?page=${pagina}&rows=${rows}&defec=${desdeFecha}&hafec=${hastaFecha}&order=${orden}&desc=${desc}`;
    // console.log(url);
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getGastosIdCaja(idCaja:number){
    const base_url = `${ environment.base_url }/Gastos`;
    const url = `${ base_url }/getGastosIdCaja/${idCaja}`;
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  nuevoGasto(data:Gasto){
    const base_url = `${ environment.base_url }/Gastos`;
    const url = `${ base_url }/postGasto`;
    // console.log(url);
    
    return this.http.post(url, data, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  actualizarGasto(data:Gasto){
    const base_url = `${ environment.base_url }/Gastos`;
    const url = `${ base_url }/putGasto/${data.id}`;
    
    return this.http.put(url, data, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  borrarGasto(idGasto:number){
    const base_url = `${ environment.base_url }/Gastos`;
    const url = `${ base_url }/borrarGasto/${idGasto}`;
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  nuevoDesglose(data:Monedero){
    const base_url = `${ environment.base_url }/DesgloseCobros`;
    const url = `${ base_url }/postDesglose`;
    // console.log(url);
    
    return this.http.post(url, data, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getDesglose(idFactura: number){
    const base_url = `${ environment.base_url }/DesgloseCobros`;
    const url = `${ base_url }/getDesglose/${idFactura}`;
    // console.log(url);
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  existeCobro(idFactura: number){
    const base_url = `${ environment.base_url }/MovimientosCaja`;
    const url = `${ base_url }/getExisteCobro/${idFactura}`;
    // console.log(url);
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

}
